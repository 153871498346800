

import React,{useContext} from "react"
import { Grid, Typography, Divider } from "@material-ui/core"
import { ReactComponent as UserIcon} from '../../../assets/user-fill.svg';
import { ControlledTextField } from "../../Core/Form/ControlledFields";
import PilotRatingAutocomplete from "./RatingAutocomplete";
import PilotCertificateSelect from "./PilotCertificateSelect";
import { ControlledTextRadioController,ControlledYesNoRadioButtonsWithDetails } from "../../Core/Form/Radio";
import { ControlledDatePicker } from "../../Core/Form/Datepickers";
import SkywatchInformationTooltip from "../../Core/Popover/SkywatchInformationTooltip";
import { ApplicantPilotCheckbox } from "./ApplicantPilotCheckbox";
import { useFormContext } from "react-hook-form";
import { QuoteContextStore } from '../../../context/QuoteContext/QuoteContext';



const PilotPastClaimsDetailsSection = ({ name, fieldName }) => {
        const { watch } = useFormContext();
        const yesNoQuestion = watch(fieldName) 
        const {locked_quote} = useContext(QuoteContextStore)
    return (
        <>
            {yesNoQuestion && (
                <ControlledTextField fullWidth name={name}  className="margin-top-8 padding-top-3 padding-bottom-3" placeholder="Please provide more details" type="text" disabled={locked_quote} />)}
        </>
    )
}

export default PilotPastClaimsDetailsSection