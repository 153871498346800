import React, { useContext } from "react"
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { BackButton } from "./BackButton";
import { QuoteContextStore } from "../../../context/QuoteContext/QuoteContext";
import './BackButton.scss'
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useParams } from 'react-router-dom';

export const FlowBackButton = (props) => {
    const {quoteId} = useParams();
    const {goBack,locked_quote} = useContext(QuoteContextStore);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return <BackButton {...props} onClick={() => goBack(quoteId,locked_quote)} variant="contained" disableElevation>{!isMobile ? "Back" : ""}</BackButton>
}
