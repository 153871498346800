import React,{useContext} from "react"
import { Grid, Typography } from "@material-ui/core"
import { ReactComponent as UserIcon} from '../../../../assets/user-fill.svg';
import { ControlledTextField } from "../../../Core/Form/ControlledFields";
import { ControlledDatePicker } from "../../../Core/Form/Datepickers";
import { ApplicantPilotCheckbox } from "../ApplicantPilotCheckbox";
import { useLocation } from "react-router-dom";
import {isInFlow} from "../../../../services/utils";
import { QuoteContextStore } from '../../../../context/QuoteContext/QuoteContext';


const PilotBasicDetailsSection = ({isRenewal=false}) => 
{
const location = useLocation()
const {locked_quote} = useContext(QuoteContextStore)
    return (<React.Fragment>
        <ApplicantPilotCheckbox />
        <Grid item xs={6} md={4}>
            <Typography variant="h4" className="grey">First name</Typography>
            <ControlledTextField disabled={!isInFlow(location.pathname) || locked_quote} variant="outlined" fullWidth={true} name="first_name" className="margin-top-8" placeholder="First name" IconComponent={UserIcon}/>
        </Grid>
        <Grid item xs={6} md={4}>
            <Typography variant="h4" className="grey">Last name</Typography>
            <ControlledTextField disabled={!isInFlow(location.pathname) || locked_quote} variant="outlined" fullWidth={true} name="last_name" className="margin-top-8" placeholder="Last name" IconComponent={UserIcon}/>
        </Grid>
        <Grid item xs={12} md={4}>
            <Typography variant="h4" className="grey">Date of birth</Typography>
            <ControlledDatePicker disabled={!isInFlow(location.pathname) || locked_quote} disableFuture name="date_of_birth" className="margin-top-8" fullWidth />
        </Grid>
    </React.Fragment>)
}
    


export default PilotBasicDetailsSection