import { ButtonGroup, Button, Radio, RadioGroup, FormControlLabel, FormHelperText, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { memo } from 'react'
import FlexView from 'react-flexview/lib'
import { KPretty } from '../../../services/utils'
import { OptionalTag } from '../OptionalTag'
import { SkyInformationPopover } from '../../Core/Popover/SkyPopover'
import { RectangleFrame } from '../Frames/RectangleFrame'
import { reportGTM } from '../../Core/Utilities/GTagManger'
import { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

const ControlledRadioLineContent = memo(({ getValues, name, register, errors, setValue, onChange, color='primary', options, isTexted = false }) => {
    useEffect(() => {
      register(name)
    }, [])

    const handleChange = (e) => {
      const{ value } = e.target;
      setValue(name, value)
      if(!onChange) return
      onChange();
    }

    return (
      <React.Fragment>
          <RadioGroup row onChange={handleChange}>
            {options.map((option) => (
              <FormControlLabel
              value={option.value}
              control={<Radio color={color} />}
              label={option.label}
              
              />))}
          </RadioGroup>
          { !isTexted && errors[name] && <FormHelperText error>{errors[name].message}</FormHelperText>}
      </React.Fragment>
    );
});

export const ControlledRadio = (props) => {
    const { errors, register, setValue, getValues } = useFormContext();
    return <ControlledRadioLineContent {...props} {...{register, setValue, errors, getValues}}/>
}

export const ControlledTextedRadio = ({children,...props}) => {
  const { errors, register, setValue } = useFormContext();
  return <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
                {children}
                { errors[props.name] && <FormHelperText error>{errors[props.name].message}</FormHelperText> }
            </Grid>
            <Grid item>
              <ControlledRadioLineContent {...props} {...{register, setValue, errors}} isTexted={true}/>
            </Grid>
          </Grid>
}

const ControlledRadioControllerContent = memo(({ name, control, errors, color='primary', options, isTexted = false }) => {
  return (
      <React.Fragment>
          <Controller
              name={name}
              control={control}
              as={
                <RadioGroup row>
                    {options.map((option) => (
                        <FormControlLabel
                            value={option.value}
                            control={<Radio color={color} />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            }
          />
      </React.Fragment>
  );
});


const RenderedControlledRadioControllerContent = memo(({ name, control, errors, color='primary', options,disabled, boolean = false }) => {
  return (
      <React.Fragment>
          <Controller
              name={name}
              control={control}
              render={(params) =>
                <RadioGroup row
                {...params}
                onChange={(e) => {
                  let value = e.target.value;
                  if(boolean) {
                    value = value === 'true';
                  }
                  params.onChange(value)
                }}>
                    {options.map((option) => (
                        <FormControlLabel
                            disabled={disabled}
                            key={option.label}
                            value={option.value}
                            control={<Radio color={color} />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            }
          />
      </React.Fragment>
  );
});

export const ControlledRadioController = (props) => {
  const { errors, control } = useFormContext();
  return <ControlledRadioControllerContent {...props} {...{ errors, control }}/>
}

export const ControlledTextRadioController = ({children, ...props}) => {
  const { errors, control } = useFormContext();
  return (
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item sm={12} md={7} lg={7} xl={8}>
              {children}
              {errors[props.name] && (
                  <FormHelperText error>
                      {errors[props.name].message}
                  </FormHelperText>
              )}
          </Grid>
          <Grid item sm={12} md={5} lg={5} xl={4}>
            <Grid container justifyContent='flex-end'>
              <RenderedControlledRadioControllerContent
                  {...props}
                  {...{ control }}
                  isTexted={true}
                  />
              </Grid>
          </Grid>
      </Grid>
  );
}
