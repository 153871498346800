import React,{useContext} from "react"
import { Grid, Typography } from "@material-ui/core"
import { ControlledTextRadioController } from "../../../Core/Form/Radio";
import PilotPastClaimsDetailsSection from "../PilotPastClaimsDetailsSection"
import { QuoteContextStore } from '../../../../context/QuoteContext/QuoteContext';


const PilotWaiverSection = () => {
    const {locked_quote} = useContext(QuoteContextStore)
    return (
        <React.Fragment>
        <Grid item md={12} lg={9}>
            <Grid container justifyContent="space-between" wrap="nowrap">
                <ControlledTextRadioController boolean name="has_medical_waivers" options={[{label: "Yes", value: true}, {label: "No", value: false}]} disabled={locked_quote}>
                    <Typography className="margin-bottom-6 gray text-body" variant="body">Does the pilot have any medical waivers other than corrective lenses or color blindness?</Typography>
                </ControlledTextRadioController>
            </Grid>               
        </Grid>
        <Grid item md={12} lg={9} container>
            <PilotPastClaimsDetailsSection name="medical_waivers_details" fieldName="has_medical_waivers" disabled={locked_quote}/>
        </Grid>
        <Grid item md={12} lg={9}>
            <Grid container justifyContent="space-between" wrap="nowrap">
                <ControlledTextRadioController boolean name="accidents_in_last5_years" options={[{label: "Yes", value: true}, {label: "No", value: false}]} disabled={locked_quote}>
                    <Typography className="margin-bottom-6 gray text-body" variant="body">In the last 5 years, has the pilot had any accidents, incidents or losses?</Typography>
                </ControlledTextRadioController>
            </Grid> 
        </Grid>
        <Grid item md={12} lg={9} container>
            <PilotPastClaimsDetailsSection name="accidents_in_last5_years_details" fieldName="accidents_in_last5_years" disabled={locked_quote}/>
        </Grid>
        <Grid item md={12} lg={9}>
            <div className="margin-bottom-6 bold text-body">In the last 5 years, has the pilot:</div>
            <Grid container justifyContent="space-between" wrap="nowrap">
                <ControlledTextRadioController boolean name="violation_or_intoxicated_or_felony_charges" options={[{label: "Yes", value: true}, {label: "No", value: false}]} disabled={locked_quote}>
                    <Typography className="margin-bottom-6 gray text-body" variant="body">Been cited for violation of any aviation regulation/had their pilot's or driver's license suspended/been convicted of driving while intoxicated/been convicted of any felony charges?</Typography>
                </ControlledTextRadioController>
            </Grid>
        </Grid>
        <Grid item md={12} lg={9} container>
            <PilotPastClaimsDetailsSection name="violation_or_intoxicated_or_felony_charges_details" fieldName="violation_or_intoxicated_or_felony_charges" disabled={locked_quote}/>
        </Grid>
    </React.Fragment>
    )
}
    

export default PilotWaiverSection