import React,{useContext} from "react"
import { Grid, Typography } from "@material-ui/core"
import { BetterControlledTextField, NumberedTextField } from "../../../Core/Form/ControlledFields";
import SkywatchInformationTooltip from "../../../Core/Popover/SkywatchInformationTooltip";
import { useFormContext } from 'react-hook-form';
import { AIRCRAFT_CLASS } from "../../../../context/QuoteContext/actions";
import { QuoteContextStore } from '../../../../context/QuoteContext/QuoteContext';


const PilotHoursSection = () => {
    const { watch, getValues, setValue } = useFormContext();
    const className = watch('class_name');
    const mandatoryHoursSize = className ? 4 : 6
    const {locked_quote} = useContext(QuoteContextStore)

    return (
        <React.Fragment>
            <Grid item xs={12} md={mandatoryHoursSize} lg={mandatoryHoursSize}>
                <SkywatchInformationTooltip text="Total Logged (Fixed Wing) hours can include your second-in-command time if logged as a required flight crew member." placement="bottom">
                    <Typography variant="h4" className="grey">Hours logged</Typography>
                </SkywatchInformationTooltip>
                <NumberedTextField fullWidth={true} name="hours_logged" className="margin-top-8" placeholder="Total hours" disabled={locked_quote}/>
            </Grid>
            <Grid item xs={12} md={mandatoryHoursSize} lg={mandatoryHoursSize}>
                <Typography variant="h4" className="grey">Hours make and model</Typography>
                <NumberedTextField fullWidth={true} name="total_hours_make_and_model" className="margin-top-8" placeholder="Total hours" disabled={locked_quote}/>
            </Grid>
            { (className === AIRCRAFT_CLASS.CG || !className)&& <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h4" className="grey">Hours tailwheel</Typography>
                <NumberedTextField fullWidth={true} name="total_hours_conventional_gear" className="margin-top-8" placeholder="Total hours" disabled={locked_quote}/>
            </Grid> }
            { (className === AIRCRAFT_CLASS.RG || !className) && <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h4" className="grey">Hours retractable gear</Typography>
                <NumberedTextField fullWidth={true} name="total_hours_retractable_gear" className="margin-top-8" placeholder="Total hours" disabled={locked_quote}/>
            </Grid> }
            { (className === AIRCRAFT_CLASS.ME || !className) && <Grid item xs={12} md={12} lg={4}>
                <Typography variant="h4" className="grey">Hours multi-engine</Typography>
                <NumberedTextField fullWidth={true} name="total_hours_multi_engine" className="margin-top-8" placeholder="Total hours" disabled={locked_quote}/>
            </Grid> }
        </React.Fragment>)
    }

export default PilotHoursSection